export const strings = {
  common: {
    yes: 'Taip',
    no: 'Ne',
    searchLabel: 'Ieškoti',
    button: {
      edit: 'Redaguoti',
    },
    pagination: {
      previous: 'Praeitas',
      next: 'Kitas',
      showing: 'Rodoma',
      from: 'nuo',
      to: 'iki',
      of: 'iš',
      items: 'įrašų',
    },
    confirmation: {
      deleteFile: {
        title: 'Ar tikrai norite ištrinti šį dokumentą?',
        description: 'Ištrynus jį, nebebus įmanoma sugrąžinti atgal.',
      },
      deleteUser: {
        title: 'Ar tikrai norite ištrinti šį vartotoją?',
        description: 'Ištrynus jį, nebebus įmanoma sugrąžinti atgal.',
      },
    },
  },
  companies: {
    registered: 'Registracijos data',
    demo: 'Demo',
    active: 'Aktyvus',
    name: 'Pavadinimas',
    subscription: 'Prenumerata',
    expires_at: 'Galioja iki',
    news_subscription: 'Naujienų prenumerata',
    press_releases: 'Pranešimai spaudai',
    filter: {
      loginState: 'Prisijungimas',
      serviceState: 'Paslaugų statusas',
      service: 'Aktyvi paslauga',
      subscriptionExpirationDate: 'Prenumerata galioja iki',
      pressReleaseExpirationDate: 'Pranešimai spaudai galioja iki',
      activeFilters: 'filtras|filtrai|filtrų',
      select: 'Filtrai',
    },
  },
  error: {
    fieldIsRequired: 'Laukas yra privalomas',
    companyNamedIsRequired: 'Įmonės pavadinimas privalomas',
    pageNotFound: {
      title: 'Puslapis nerastas',
      description: 'Patikrinkite adresą arba bandykite dar kartą.',
      goHome: 'Grįžti į pradžią',
    },
  },
  form: {
    email: 'El.pašto adresas',
    password: 'Slaptažodis',
    error: {
      emailFormat: 'Įvestas tekstas enatitinka el. pašto formato',
    },
  },
  filter: {
    clearFilter: 'Išvalyti',
  },
  button: {
    login: 'Prisijungti',
    close: 'Uždaryti',
    edit: 'Redaguoti',
    view: 'Peržiūrėti',
    delete: 'Ištrinti',
    pin: 'Pažymėti',
    unpin: 'Atžymėti',
    save: 'Išsaugoti',
    comment: 'Komentuoti',
    cancel: 'Atšaukti',
    removeAll: 'Remove All',
  },
  navigation: {
    returnToMainAdmin: 'Grįžti į seną versiją',
    companies: 'Įmonės',
    signOut: 'Atsijungti',
  },
  search: {
    noResults: 'Nerasta jokių įrašų',
  },
  payments: {
    periodicityOptions: {
      monthly: 'Kas mėnesį',
      singleUse: 'Vienkartinis',
      individual: 'Individualus',
    },
    invoiceBy: {
      invoice_email: 'El. paštu',
      invoice_einvoice: 'E. sąskaita faktūra',
    },
  },
  documents: {
    types: {
      contract: 'Sutartis',
      appendix: 'Priedas',
      other: 'Kita',
    },
  },
};
