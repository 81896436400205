import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { useAuth } from '../../hooks/use-auth';
import * as companiesService from '../../services/api/companies';
import { Company } from '../../entities/company';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import { companyComments } from '../../services/api/comments';
import { Comment } from '../../entities/comment';
import CommentsComponent from '../../components/companies/comments';
import { CommentsContextProvider } from '../../context/comments';
import { CompanyContextProvider } from '../../context/company';
import CompanyInfoBlock from './components/company-info-block';
import DocumentsBlock from './components/documents-block';
import InvoicesBlock from './components/invoices';
import { companyAdminStructure } from '../../services/api/categories';
import { CompanyAdminStructure } from '../../entities/company-admin-structure';
import { useHash } from '../../hooks/use-hash';
import ServicesTabComponent from './components/services';
import ExportNews from './components/export-news';
import UsersTab from './components/users';

const tabs = [
  { name: 'Įmonės duomenys ir Komentarai', href: '#main', current: false },
  { name: 'Vartotojai', href: '#users', current: false },
  { name: 'Užsakytos paslaugos', href: '#services', current: true },
  { name: 'Eksportas', href: '#export', current: false },
];

function CompaniesViewPageComponent() {
  const { id } = useParams();

  const { token } = useAuth();
  const [currentTab, setCurrentTab] = useHash(tabs[0].href);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [item, setItem] = useState<Company>({} as Company);
  const [structure, setStructure] = useState<CompanyAdminStructure>({} as CompanyAdminStructure);
  const [commentsFetched, setCommentsFetched] = useState<Comment[]>([]);

  useEffect(() => {
    try {
      const fetchData = async (companyId: string) => {
        const companyInfo = await companiesService.one(token, companyId);
        const categoryStructure = await companyAdminStructure(token);

        setItem(companyInfo);
        setStructure(categoryStructure);

        setIsLoading(false);
      };

      setIsLoading(true);

      if (id) {
        fetchData(id);
      }
    } catch (e) {
      // handle your error
      setIsLoading(false);
      setError((e as Error).message);
    }
  }, [token, id]);

  useEffect(() => {
    try {
      if (id) {
        const fetchComments = async () => {
          const fetchedComments = await companyComments(token, Number(id));

          setCommentsFetched(fetchedComments.items);
          // setPagination(fetchedComments.pagination);
        };

        fetchComments();
      }
    } catch (e) {
      // handle your error
      setIsLoading(false);
      setError((e as Error).message);
    }
  }, [token, id]);

  return (
    <CompanyContextProvider companyStructure={structure} companyDetails={item}>
      <div>
        <header className="mb-3">
          <div>
            <h1 className="text-3xl font-bold leading-tight text-gray-900">{item ? item.name : ''}</h1>
          </div>
        </header>
        <main className="w-full">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={tabs.find((tab) => tab.href === currentTab)?.name}
              onChange={(e) => setCurrentTab(e.target.value)}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    onClick={() => setCurrentTab(tab.href)}
                    className={classNames(
                      tab.href === currentTab
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium no-underline',
                    )}
                    aria-current={tab.href === currentTab ? 'page' : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>

          {error && (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
              <AlertPrimary text={error} />
            </div>
          )}
          {isLoading ? (
            <CommonAnimatedLoader />
          ) : (
            <div className="max-w-7xl py-8">
              {currentTab === '#main' && (
                <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                  <div className="lg:col-start-3 lg:row-end-1">
                    <InvoicesBlock />
                  </div>
                  <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2 -mx-4 sm:mx-0">
                    <div className="mb-4">
                      <CompanyInfoBlock />
                    </div>
                    <div className="bg-white p-4 shadow sm:rounded-lg ring-1 ring-gray-900/5">
                      <CommentsContextProvider comments={commentsFetched}>
                        <CommentsComponent />
                      </CommentsContextProvider>
                    </div>
                  </div>
                  <div className="lg:col-start-3">
                    <DocumentsBlock />
                  </div>
                </div>
              )}
              {currentTab === '#users' && <UsersTab />}
              {currentTab === '#services' && <ServicesTabComponent />}
              {currentTab === '#export' && <ExportNews />}
            </div>
          )}
        </main>
      </div>
    </CompanyContextProvider>
  );
}

export default CompaniesViewPageComponent;
